<template>
  <v-form ref="form">
    <v-layout wrap justify-center>
      <ServerError v-if="ServerError" />
      <vue-element-loading
        :active="appLoading"
        :is-full-screen="true"
        background-color="#FFFFFF"
        color="blue"
        spinner="mini-spinner"
      />
      <v-flex xs12 lg11 pa-0 pa-sm-6>
        <v-card
          class="mx-auto elevation-2 pa-6"
          max-width="1088px"
          align="center"
          outlined
        >
          <v-form ref="form1" v-model="valid">
            <v-layout>
              <v-flex
                xs12
                class="text-left nsbold"
                style="font-weight: 700; font-size: 20px"
              >
                Add Purchase Details</v-flex
              >
            </v-layout>
            <v-layout wrap>
              <v-flex xs12>
                <v-card outlined class="pa-2 px-4">
                  <v-layout wrap py-lg-2>
                    <v-flex xs12 lg6 pr-0 pr-lg-1>
                      <v-text-field
                        v-model="billNo"
                        dense
                        label="Bill No"
                        outlined
                        hide-details
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12 lg6 pl-0 pl-lg-1 pt-2 pt-lg-0>
                      <v-text-field
                        v-model="shopid"
                        dense
                        label="Shop name"
                        outlined
                        hide-details
                      ></v-text-field>
                      <!-- <v-autocomplete
                      :items="shopName"
                      v-model="shopid"
                      label="Select Shop"
                      outlined
                      item-text="shopName"
                      item-value="_id"
                      dense
                      class="my-0 py-0"
                    ></v-autocomplete> -->
                    </v-flex>
                  </v-layout>
                  <v-layout wrap pb-2>
                    <v-flex xs12 pr-0 pr-lg-1 pt-3 pt-lg-0 lg6>
                      <v-text-field
                        v-model="totalAmount"
                        dense
                        label="Total Cost"
                        outlined
                        type="number"
                        disabled
                        hide-details
                      ></v-text-field>
                    </v-flex>

                    <v-flex xs12 pl-0 pl-lg-1 pt-2 pt-lg-0 lg6>
                      <v-text-field
                        v-model="discount"
                        dense
                        label="Discount (Rs.)"
                        outlined
                        type="number"
                        hide-details
                      ></v-text-field>
                    </v-flex>

                    <v-flex xs12 pr-0 pr-lg-1 pt-2 lg6>
                      <v-text-field
                        v-model="payableAmount"
                        dense
                        label="Total amount paid to retailer"
                        outlined
                        type="number"
                        hide-details
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12 pl-0 pl-lg-1 pt-2 pb-4 pb-lg-0 lg6>
                      <v-select
                        :items="HostelList"
                        v-model="hostel"
                        label="Hostel"
                        item-text="hostelName"
                        item-value="_id"
                        clearable
                        outlined
                        dense
                      ></v-select>
                    </v-flex>
                    <v-flex xs12 pr-0 pr-lg-1 pt-2 lg6>
                      <v-menu
                        ref="menu"
                        v-model="menu"
                        :close-on-content-click="false"
                        :return-value.sync="purchaseDate"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="purchaseDate"
                            label="Date"
                            outlined
                            readonly
                            dense
                            :rules="[rules.required]"
                            class="rounded-0"
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="purchaseDate"
                          no-title
                          scrollable
                          @change="$refs.menu.save(purchaseDate)"
                        >
                          <v-spacer></v-spacer>
                        </v-date-picker>
                      </v-menu>
                    </v-flex>
                  </v-layout>
                </v-card>
              </v-flex>

              <v-flex xs12>
                <v-layout wrap pt-2 pb-4>
                  <v-flex xs12 lg3 pr-lg-1>
                    <v-autocomplete
                      :items="assetType"
                      v-model="kindOfAssetType"
                      label="Select Item"
                      item-text="name"
                      item-value="_id"
                      dense
                      outlined
                    ></v-autocomplete>
                  </v-flex>
                  <v-flex xs12 lg3 pt-8 pt-lg-0 pr-lg-1>
                    <v-text-field
                      v-model="purchaseAmount"
                      dense
                      label="Price"
                      outlined
                      hide-details
                      type="number"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 lg3 pt-4 pt-lg-0 pr-lg-1>
                    <!-- :append-outer-icon="myQty" -->
                    <v-text-field
                      v-model="quantity"
                      dense
                      :label="'Quantity'"
                      outlined
                      hide-details
                      type="number"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 lg3 pt-2 pt-lg-0>
                    <v-btn
                      :disabled="shopckeck == true"
                      style="text-transform: none"
                      block
                      dark
                      color="#766BC0"
                      dense
                      @click="addValues()"
                    >
                      <span
                        class="text-left nsregular"
                        style="font-weight: 400; font-size: 13px"
                        >Add</span
                      >
                      <v-icon x-small>mdi-plus</v-icon>
                    </v-btn>
                  </v-flex>
                </v-layout>
              </v-flex>
              <v-flex xs12>
                <v-divider></v-divider>
              </v-flex>
              <v-flex xs12 py-2>
                <v-card flat>
                  <v-layout wrap>
                    <v-flex xs12 pt-1 v-for="(item, i) in itemz" :key="i">
                      <v-layout wrap pt-2>
                        <v-flex xs12 lg3 pr-lg-1>
                          <v-autocomplete
                            :items="assetType"
                            v-model="item.assetid"
                            label="Select Item"
                            solo
                            item-text="name"
                            item-value="_id"
                            dense
                            class="my-0 py-0"
                          ></v-autocomplete>
                        </v-flex>
                        <v-flex xs12 lg3 pt-8 pt-lg-0 pr-lg-1>
                          <v-text-field
                            v-model="item.purchaseAmount"
                            dense
                            label="Price"
                            type="number"
                            solo
                            :rules="[rules.required]"
                            hide-details
                            @input="calcPrice()"
                          ></v-text-field>
                        </v-flex>
                        <v-flex xs12 lg3 pt-4 pt-lg-0 pr-lg-1>
                          <!-- :append-outer-icon="myQty" -->
                          <v-text-field
                            v-model="item.quantity"
                            dense
                            type="number"
                            :label="
                              'quantity' + ' ' + ' (' + item.unitType + ')'
                            "
                            solo
                            hide-details
                            :rules="[rules.required]"
                            @input="calcPrice()"
                          ></v-text-field>
                        </v-flex>
                        <v-flex xs12 lg3 pt-2 pt-lg-0>
                          <v-btn
                            block
                            dark
                            style="background-color: #766bc0"
                            dense
                            @click="(dialoge = true), (removeitemIndex = i)"
                          >
                            Remove
                          </v-btn>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </v-card>
              </v-flex>
            </v-layout>
            <v-layout wrap>
              <v-flex pt-1>
                <input
                  v-show="false"
                  ref="files"
                  type="file"
                  multiple
                  @change="getDocs"
                />
                <v-btn
                  dark
                  style="background-color: #766bc0; text-transform: none"
                  block
                  @click="$refs.files.click()"
                  ><span
                    class="text-left nsregular"
                    style="font-weight: 400; font-size: 13px"
                    >Upload Bill</span
                  ></v-btn
                >
              </v-flex>
              <!-- </v-layout>
          <v-layout wrap> -->
              <v-flex xs12>
                <v-chips v-for="(item, i) in bills" :key="i">
                  <v-chip
                    class="mt-2"
                    @click:close="bills.splice(i, 1)"
                    close
                    dark
                    color="primary"
                    style="margin-right:6px;"
                  >
                    {{ item.name }}
                  </v-chip>
                </v-chips>
              </v-flex>
            </v-layout>
            <v-layout wrap v-if="itemz.length > 0">
              <v-flex mt-3>
                <v-btn
                  dark
                  style="background-color: #766bc0;"
                  block
                  @click="submit()"
                  >Save</v-btn
                >
              </v-flex>
            </v-layout>
          </v-form>
          <!------------------------------------------------>
        </v-card>
        <v-dialog width="400px" v-model="dialoge">
          <v-card width="400px">
            <v-toolbar dark color="grey" dense flat class="body-2">
              <v-toolbar-title> Confirm </v-toolbar-title>
            </v-toolbar>
            <v-card-text class="pa-4 black--text"
              >Are you sure you want to remove this item?
            </v-card-text>
            <v-card-actions class="pt-3">
              <v-spacer></v-spacer>
              <v-btn
                color="grey"
                text
                class="body-2 font-weight-bold"
                @click="dialoge = false"
                >Cancel</v-btn
              >
              <v-btn
                color="primary"
                class="body-2 font-weight-bold"
                outlined
                @click="
                  itemz.splice(removeitemIndex, 1),
                    calcPrice(),
                    (dialoge = false)
                "
                >OK</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-snackbar
          :timeout="3000"
          :value="true"
          right
          color="red"
          v-model="snackbar"
        >
          <span style="font-size: 20px"> {{ msg }}</span>
        </v-snackbar>
      </v-flex>
    </v-layout>
  </v-form>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      removeitemIndex: null,
      shopckeck: false,
      kindOfAssetType: null,
      purchaseAmount: null,
      quantity: null,
      unitType: "",
      rules: {
        required: (value) => !!value || "Required.",
      },
      HostelList: [],
      hostel: null,
      hostelName: null,
      itemz: [],
      dialoge: false,
      singleitem: {
        assetid: null,
        purchaseAmount: null,
        quantity: null,
      },
      valid: true,
      shopid: null,
      assetType: [],
      shopName: [],
      food: "",
      myQty: "",
      // billNo:null,
      snackbar: false,
      msg: null,
      appLoading: false,
      ServerError: false,
      billNo: null,
      totalAmount: null,
      discount: null,
      bills: [],
      allDocuments: new FormData(),
      purchaseDate: new Date(
        Date.now() - new Date().getTimezoneOffset() * 60000
      )
        .toISOString()
        .substr(0, 10),
      uploadId: null,
      cacheAmount: null,
      payableAmount: null,
    };
  },
  mounted() {
    this.getAssetType();
    this.getShopName();
    this.gethostel();
  },

  watch: {
    hostelName() {
      this.getData();
    },
    payableAmount() {
      if (this.totalAmount < this.payableAmount) {
        this.msg = "Total cost and total amount is different!";
        this.snackbar = true;
      }
      //if needed uncomment this
      if (this.payableAmount < this.totalAmount - this.discount) {
        this.msg = "Amount is much lesser than the total!";
        this.snackbar = true;
      }
    },
    totalAmount() {
      this.payableAmount = this.totalAmount - this.discount;
    },
    discount() {
      // if(this.payableAmount)
      this.payableAmount = this.totalAmount - this.discount;
    },
  },
  methods: {
    // checkBill() {
    //   if (this.billNo != null && this.shopid != null) {
    //     axios({
    //       method: "post",
    //       url: "/check/duplicate/bill",
    //       headers: {
    //         token: localStorage.getItem("token"),
    //       },
    //       data: {
    //         billNo: this.billNo,
    //         shopid: this.shopid,
    //       },
    //     })
    //       .then((response) => {
    //         this.appLoading = false;
    //         if (response.data.status == true) {
    //           if (response.data.alreadyExists == true) {
    //             this.shopckeck = true;
    //             this.msg = "Bill already exist";
    //             this.snackbar = true;
    //           } else {
    //             this.shopckeck = false;
    //           }
    //         } else {
    //           this.snackbar = true;
    //           this.msg = response.data.msg;
    //         }
    //       })
    //       .catch((err) => {
    //         console.log(err);
    //         this.appLoading = false;
    //       });
    //   }
    // },

    addValues() {
      if (
        this.kindOfAssetType != null &&
        this.purchaseAmount != null &&
        this.quantity != null
      ) {
        this.singleitem.assetid = this.kindOfAssetType;
        this.singleitem.purchaseAmount = this.purchaseAmount;
        this.singleitem.quantity = this.quantity;

        this.calcPrice2(this.purchaseAmount, this.quantity);

        this.addVariant();
      } else {
        this.snackbar = true;
        this.msg = "please fill your form";
      }
    },

    getDocs(event) {
      var myDoc = event.target.files;

      for (let i = 0; i < myDoc.length; i++) {
        this.bills.push(myDoc[i]);
      }
    },

    addVariant() {
      this.itemz.push(JSON.parse(JSON.stringify(this.singleitem)));
      this.kindOfAssetType = null;
      this.purchaseAmount = null;
      this.quantity = null;
    },

    calcPrice2(val1, val2) {
      var totalPrice2 = 0;

      if (val1 && val2) {
        totalPrice2 += val1 * val2;
      }

      this.totalAmount = this.totalAmount + totalPrice2;
      this.payableAmount = this.totalAmount - this.discount;
    },

    calcPrice() {
      var totalPrice = 0;
      for (let i = 0; i < this.itemz.length; i++) {
        if (this.itemz[i].quantity && this.itemz[i].purchaseAmount) {
          totalPrice += this.itemz[i].quantity * this.itemz[i].purchaseAmount;
        }
      }
      this.totalAmount = totalPrice;
    },

    getAssetType() {
      this.appLoading = true;
      axios({
        method: "get",
        url: "/asset/getalllist",
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.assetType = response.data.data;
            this.food = response.data.data.quantityType;

            this.msg = response.data.msg;
          } else {
            this.snackbar = true;
            this.msg = response.data.msg;
          }

          // this.msg = response.data.msg;

          // this.snackbar = true;
        })
        .catch((err) => {
          console.log(err);
          this.appLoading = false;
        });
    },
    gethostel() {
      axios({
        method: "get",
        url: "/hostel/name/getlist",
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          if (response.data.status == true) {
            this.HostelList = response.data.data;
            this.hostelName = this.HostelList[0]._id;
          }
        })
        .catch((err) => {
          console.log(err());
        });
    },
    getShopName() {
      this.appLoading = true;
      axios({
        method: "get",
        url: "/supplier/getalllist",
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.shopName = response.data.data;

            this.msg = response.data.msg;
          } else {
            this.snackbar = true;
            this.msg = response.data.msg;
          }

          // this.msg = response.data.msg;

          // this.snackbar = true;
        })
        .catch((err) => {
          console.log(err);
          this.appLoading = false;
        });
    },
    uploadBills(value) {
      for (let i = 0; i < this.bills.length; i++) {
        this.allDocuments.append("photos", this.bills[i]);
      }
      this.allDocuments.append("id", value);
      axios({
        method: "post",
        url: "/upload/asset/bills",
        headers: {
          token: localStorage.getItem("token"),
        },

        data: this.allDocuments,
      })
        .then((response) => {
          if (response.data.status == true) {
            this.snackbar = true;
            this.msg = response.data.msg;
            this.bills = [];

            // this.$router.go(-1);
          } else {
            this.snackbar = true;
            this.msg = response.data.msg;
          }
          this.$refs.form1.resetValidation();
        })
        .catch((err) => {
          console.log(err);
        });
    },

    submit() {
      // allDocuments

      this.sockets.subscribe("New_Purchase", () => {
        this.msg = "Teste";
        this.snackbar = true;
      });

      axios({
        method: "post",
        url: "/asset/purchase",
        headers: {
          token: localStorage.getItem("token"),
        },

        // data:this.allDocuments
        data: {
          shopName: this.shopid,
          billNo: this.billNo,
          totalAmount: this.totalAmount,
          discount: this.discount,
          assets: this.itemz,
          payableAmount: this.payableAmount,
          hostelName: this.hostel,
          purchaseDate: this.purchaseDate,
        },
      })
        .then((response) => {
          if (response.data.status) {
            // this.snackbar = true;
            // this.msg = response.data.msg;
            this.uploadId = response.data.bill._id;
            if (this.bills.length > 0) this.uploadBills(this.uploadId);
            this.msg = response.data.msg;
            this.snackbar = true;
            // this.$router.go(-1);
            this.shopid = null;
            this.billNo = null;
            this.totalAmount = null;
            this.discount = null;
            this.payableAmount = null;
            this.hostel = null;

            this.itemz = [];
          } else {
            this.msg = response.data.msg;
            this.snackbar = true;
          }
          this.$refs.form1.resetValidation();
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
<style scoped>
.v-select {
  height: 20px !important;
}
.v-text-field .v-input__control .v-input__slot {
  max-height: 10px !important;
}
</style>
